body {
    font-family: "Inter";
    background-color: var(--color-background);
}

.navbar {
    .navbar-brand {
        img {
            height: 24px;
            width: 24px;
        }
    }
}

.about {
    h2 {
        font-size: 32px;
        font-weight: 800;
    }

    p {
        font-size: 26px;
        font-style: normal;
        font-weight: 300;
    }
}